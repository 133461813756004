import React, { useEffect } from "react"
import { graphql, Link } from "gatsby"
import Img from "gatsby-image"
import Layout from "../components/Layout"

// import RelatedPosts from '../components/RelatedPosts'
import htmlDecode from "html-entities-decoder";
import { format } from 'date-fns'
import UserIcon from "../images/user.svg";

import "../components/Blog/PostTemplate.css"


// import {
//     EmailShareButton,
//     FacebookShareButton,
//     WhatsappShareButton,
//     FacebookIcon,
//     WhatsappIcon,
//     EmailIcon
// } from "react-share";


function PostTemplate(props) {
    const {
        data: {
            wpgraphql: { post, menu, footer, template },
        },
    } = props;

    console.log(props)

    function preloadImage(img) {
        const src = img.getAttribute("data-src");
        if (!src) {
            return;
        }
        img.src = src;
    }

    const imgOptions = {
        threshold: 0,
        rootMargin: "0px 0px 300px 0px"
    };

    useEffect(() => {

        let imgObserver = new IntersectionObserver((entries, imgObserver) => {
            entries.forEach(entry => {
                if (!entry.isIntersecting) {
                    return;
                } else {
                    preloadImage(entry.target);
                    imgObserver.unobserve(entry.target);
                }
            })
        }, imgOptions)

        let images = document.querySelectorAll("[data-src]");
        images.forEach(image => {
            imgObserver.observe(image)
        })

    }, [])


    function addLazyLoadHandler(content) {
        let lazyLoadedContent;
        lazyLoadedContent = content.replace(/src="/g, 'data-src="')
        lazyLoadedContent = lazyLoadedContent.replace(/srcset="/g, 'data-srcset="')
        return lazyLoadedContent;
    }


    function constructMetaData(page, currentPage) {
        let payload = {
            title: page.seo.title,
            description: page.seo.metaDesc,
            canonical: `https://nextyounutrition.gr/${currentPage}`,
            fbTitle: page.seo.opengraphTitle ? page.seo.opengraphTitle : page.seo.title,
            fbDescription: page.seo.opengraphDescription ? page.seo.opengraphDescription : page.seo.metaDesc,
            fbUrl: `https://nextyounutrition.gr/${currentPage}`,
            fbType: "website",
            locale: 'el',
            image: page.seo.opengraphImage ? page.seo.opengraphImage.sourceUrl : ""
        }
        return payload
    }

    return (
        <Layout header={menu} footer={footer} appointment= {template.appCpt} metaData={constructMetaData(post, props.pageContext.currentPage)} >

            <article className="container mx-auto mt-16">
                <div className="sp-img-container w-full relative" >
                    {post.featuredImage && (
                        <Img
                            fluid={post.featuredImage.imageFile.childImageSharp.fluid}
                            alt={post.featuredImage.altText}
                            className=" w-full rounded pb-6 sp-f-img"
                        />
                    )}
                    <p className="absolute bottom-0 w-full text-center text-white sp-date">{format(new Date(post.date), 'MMMM dd, yyyy')}</p>
                </div>
                <div className="title-container pt-16" >
                    <div>
                        <div className="flex justify-center">
                            <div style={{fontSize: "1.3rem;"}}>
                                <span style={{ fontWeight: 600 }}>Δημοσιεύτηκε από: </span>
                                {post.author.name}
                            </div>
                        </div>
                        <div className="w-full pt-4 pb-12">
                            <div className="m-auto relative text-center">
                                <h1 className="sp-h1">
                                    {htmlDecode(post.title)}
                                </h1>
                            </div>
                        </div>

                    </div>
                </div>
                <div>
                    <div className="sp-content-wrapper">
                        <div dangerouslySetInnerHTML={{ __html: addLazyLoadHandler(post.content) }} />
                    </div>
                </div>
                <div className="related mt-16">
                    {post.relatedPosts.relatedPosts && (
                        <div className="w-full pt-4 pb-12">
                            <div className="m-auto relative text-center">
                                <h4 className="sp-h4 relative">
                                    Σχετικά Άρθρα
                                </h4>
                                <div className="w-full flex flex-wrap mt-8">

                                    {post.relatedPosts.relatedPosts.map((post, i) => (
                                        <article className="w-full md:w-1/2 lg:w-1/3 p-4" key={`post-tab-${i}`}>
                                            <div className="relative bp-article">
                                                <div>
                                                    {post.featuredImage && (
                                                        <Img
                                                            fluid={post.featuredImage.imageFile.childImageSharp.fluid}
                                                            alt={post.featuredImage.altText}
                                                            className="h-full w-full shadow bp-img-wrapper"
                                                            loading="lazy"
                                                        />
                                                    )}
                                                </div>
                                                <div className="absolute top-0 w-full text-white z-10">
                                                    <div className="flex pt-4 px-8 ">
                                                        <div className="flex justify-center">
                                                            <div>
                                                                <img src={UserIcon} style={{ width: "20px", marginRight: "5px" }} />
                                                            </div>
                                                            <div>
                                                                {post.author.firstName}
                                                            </div>
                                                        </div>
                                                        <div className="px-2">-</div>
                                                        <div>{format(new Date(post.date), 'MMMM dd, yyyy')}</div>
                                                    </div>
                                                </div>
                                                <div className=" p-2 md:p-6 py-4 md:py-6  text-center bp-info">
                                                    <h4>{htmlDecode(post.title)}</h4>
                                                    <Link to={`/blog/${post.slug}`} className="my-2">Περισσότερα</Link>
                                                </div>
                                            </div>
                                        </article>
                                    ))}
                                </div>
                            </div>
                        </div>
                    )}
                </div>

            </article>
        </Layout>

    )
}

export default PostTemplate

export const pageQuery = graphql`
    query GET_POST($id: ID!) {
        wpgraphql {
            post(id: $id) {
                slug
                title
                content
                date
                author {
                    name
                    avatar {
                        url
                    }
                }
                seo {
                    title
                    metaDesc
                    opengraphDescription
                    opengraphTitle
                    opengraphImage {
                        sourceUrl
                    }
                }
                featuredImage {
					sourceUrl
					altText
					imageFile {
					  childImageSharp {
					  fluid{
						base64
						aspectRatio
						src
						srcSet
						srcWebp
						srcSetWebp
						sizes
					  }
					  }
					}
				}
                postRT{
                    postReadTime
                }
                relatedPosts {
                    relatedPosts {
                        ... on WPGraphQL_Post {
                            slug
                            title
                            date
                            author{
                                firstName
                            }
                            featuredImage{
                                sourceUrl
								altText
								imageFile {
									childImageSharp {
										fluid(maxWidth: 1200, quality:80){
										base64
										aspectRatio
										src
										srcSet
										srcWebp
										srcSetWebp
										sizes
										}
									}
								}
							}
                        }
                    }
                }
            }
            menu(id:"bmF2X21lbnU6MjI="){
                menuItems{
                    edges{
                        node{
                            url
                            label
                            childItems {
                                edges {
                                    node {
                                        label
                                        url
                                    }
                                }
                            }
                        }
                    }
                }
            }
            template(id: "cG9zdDoyMDUw"){
                appCpt {
                    ...AppointmentFragment
                }
            }
            footer: template(id:"cG9zdDoyMjE5"){
                id
                title
                footerInformationTemplate{
                    address{
                        addressGoogleLink
                        addressLabel
                    }
                    hoursLabel
                    hoursTitle
                    informationTitle
                    supportEmail
                    telephoneNumber
                }
                footerAcf{
                    googlaMapsImage{
                        sourceUrl
                        altText
                        imageFile{
                            childImageSharp{
                                fluid(maxWidth: 750, quality:100){
                                    base64
                                    aspectRatio
                                    src
                                    srcSet
                                    srcWebp
                                    srcSetWebp
                                    sizes
                                }
                            }
                        }
                    }
                    city
                    callToActionText
                }
            }
        }
    }
`
